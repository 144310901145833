import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {IPageSearchConfig, PageSearchScope} from "@core/models/page-search.models";
import {Observable} from "rxjs";
import {ObservableStack, permanentCache} from "@juulsgaard/rxjs-tools";

@Injectable({providedIn: 'root'})
export class PageSearchService {

  private scheduler = new ObservableStack<IPageSearchConfig<any>>();

  public hasConfig$: Observable<boolean>;
  public configs$: Observable<IPageSearchConfig<any>[]>;

  constructor() {
    this.configs$ = this.scheduler.items$.pipe(
      map(items => {
        items = [...items].reverse();
        const scopeIndex = items.findIndex(x => x instanceof PageSearchScope)
        if (scopeIndex < 0) return items;
        return items.slice(0, scopeIndex);
      }),
      permanentCache(),
    );

    this.hasConfig$ = this.configs$.pipe(
      map(x => !!x.length),
      permanentCache(),
    );
  }

  addConfig(config: IPageSearchConfig<any>) {
    this.scheduler.push(config);
    return config
  }

  removeConfig(config?: IPageSearchConfig<any>) {
    if (!config) return;
    this.scheduler.removeItem(config);
  }

}
