import {Observable, of} from "rxjs";
import {ActivatedRoute} from "@angular/router";


export interface PageSearchItem<TModel> {
  id: string;
  model: TModel;
  name: string;
  icon?: string;
  extra?: string;
  score: number;
}

export interface IPageSearchConfig<TModel> {
  name: string;
  onClick?: (model: TModel, hasRoute: boolean) => void,
  route?: (model: TModel) => string[]|undefined,
  routeRelativeTo?: ActivatedRoute,
  subscribe(query$: Observable<string>): Observable<PageSearchItem<TModel>[]>;
  fallbackIcon?: string;
}

export class PageSearchScope implements IPageSearchConfig<void> {
  name = 'Scope';
  onClick = () => undefined;
  subscribe(query$: Observable<string>) {
    return of([]);
  }
}
